// if (module.hot) {
// 	module.hot.accept();
// }
import gsap from "gsap";
var $ = require("jquery");
var jQuery = require("jquery");
global.jQuery = require('jquery');
var jQBridget = require('jquery-bridget');
var Isotope = require('isotope-layout');
var imagesLoaded = require('imagesloaded');
var slick =  require('slick-carousel');
var AOS = require('aos');
var fancybox = require('@fancyapps/fancybox');

jQuery(document).ready(function() {
	jQBridget( 'isotope', Isotope );
	initSmoothScroll();
});



jQuery(function() {
	initFancybox();
	initMobileNav();
	initNavigationSelect();
	switchTabBySelect();
	initCountDown();
	initFixedHeader();
	initSlickSlider();
	initTimelineSlider();
	initOpenClose();
	initFilter();
	initAos();
});

jQuery(window).on('load', function() {
	jQuery("#poppupimage").fancybox({
		touch: false,
		afterShow: function(self) {
			self.$trigger.find('a').on('click', function() {
				location.href = this.href;
			})
		}
	}).trigger('click');
});



function initSmoothScroll() {
	var link = jQuery('a[href*="#"]:not([data-fancybox]):not([href="#"])');
	link.on('click', function(e) {
		var href = jQuery(this).attr('href');

		if (jQuery(this).attr('href').slice(0, 1) == '#') {
			e.preventDefault();
			jQuery('html,body').stop().animate({
				scrollTop: jQuery(href).offset().top  - 53 + 'px' 
			}, 500);
		} else {
			var getUrl = window.location;
			if (getUrl.pathname.split('/')[1] == href.split('#')[0].replace(/[/]/g, '')) {
				var scrollBlock = jQuery('#' + href.split('#')[1]).length ? jQuery('#' + href.split('#')[1]) : jQuery('.filter-block');
				window.location.href=jQuery(this).attr('href');
				window.location.reload()
				var currentid = jQuery('#' + href.split('#')[1]);

			}
		}

	});

	if (window.location.hash) {
		var scrollBlock = jQuery(window.location.hash).length ? jQuery(window.location.hash) : jQuery('.filter-block');
		setTimeout(function() {
			jQuery('html,body').animate({
				scrollTop: scrollBlock.offset().top - 53 + 'px' 
			}, 500);
		}, 500);
	}
}

function initAos() {
	AOS.init({
		once: true,
		duration: 600
	});
}

// open-close init
function initOpenClose() {
	jQuery('.social-block').openClose({
		activeClass: 'active',
		opener: '.social-opener',
		slider: '.drop',
		animSpeed: 400,
		effect: 'slide'
	});
}



function initTimelineSlider() {
	jQuery('.timeline-slider').each(function() {
		var holder = jQuery(this);
		var btnPrev = holder.find('.btn-prev');
		var btnNext = holder.find('.btn-next');
		var slideset = holder.find('.slideset');
		var slides = holder.find('.slide');
		var opener = holder.find('.slide-opener');
		var activeIndex = 0;
		function calcWidth() {
			var totalWidth = 0;
			slides.each(function() {
				totalWidth += jQuery(this).outerWidth();
			});
			return totalWidth;
		}

		function slideChange(index) {

			
			slides.removeClass('active');
			jQuery(slides[index]).addClass('active');
			activeIndex = index;
			gsap.to(slideset, {x: - (index)*35});
			if (activeIndex == 0) {
				btnPrev.addClass('disabled');
			} else {
				btnPrev.removeClass('disabled');
			}

			if (activeIndex == slides.length-1) {
				btnNext.addClass('disabled');
			} else {
				btnNext.removeClass('disabled');
			}
		}


		function checkEmpty(value) {
			if (value) {
				activeIndex = activeIndex + 1;

				if (!jQuery(slides[activeIndex]).find('.slide-opener').hasClass('active')) {
					checkEmpty(true);
				} 
			} else {
				activeIndex = activeIndex - 1;
				if (!jQuery(slides[activeIndex]).find('.slide-opener').hasClass('active')) {
					checkEmpty(false);
				}
			}
			
			
		}


		gsap.set(slideset, {width: calcWidth(), x: 0});
		slideChange(jQuery(slides.filter('.active')).index());

		opener.on('click', function(e) {
			e.preventDefault();
			var parentEl = jQuery(this).closest('.slide');
			slides.removeClass('active');
			slides.removeClass('active');
			jQuery(slides[parentEl.index()]).addClass('active');
			activeIndex = parentEl.index();
			if (activeIndex == 0) {
				btnPrev.addClass('disabled');
			} else {
				btnPrev.removeClass('disabled');
			}

			if (activeIndex == slides.length-1) {
				btnNext.addClass('disabled');
			} else {
				btnNext.removeClass('disabled');
			}
			
			if (window.innerWidth < 768) {
				gsap.to(slideset, {x: - (activeIndex)*35});
			}

			function resizeHandler() {
				var drop = parentEl.find('.slide-drop');
				if (drop.offset().left - holder.offset().left < 0) {
					drop.find('.slide-wrap').css({marginLeft: Math.abs(drop.offset().left - holder.offset().left) });
				} 
				if ((drop.offset().left + drop.outerWidth()) > holder.offset().left + holder.outerWidth() ) {
					drop.find('.slide-wrap').css({marginLeft: -Math.abs((drop.offset().left + drop.outerWidth()) - (holder.offset().left + holder.outerWidth())) });
				} 
			}
			resizeHandler();
			jQuery(window).on('resize', resizeHandler);
			
		});

		btnPrev.on('click', function(e) {
			e.preventDefault();
			checkEmpty(false);
			slideChange(activeIndex);

		});

		btnNext.on('click', function(e) {
			e.preventDefault();
			checkEmpty(true);
			slideChange(activeIndex);
		})
	});
}

function initSlickSlider() {

	jQuery(".slider").slick({
		autoplay: true,
		autoplaySpeed: 5000,
		arrows: false,
		fade: true,
		dots: true,
		slidesToScroll: 1,
		rows: 0,
	});

	jQuery(".event-slider").slick({
		arrows: false,
		dots: true,
		inifite: false,
		slidesToScroll: 1,
		adaptiveHeight: true,
		rows: 0,
	});
}


function initFixedHeader() {
	var header = jQuery('#header');
	var win = jQuery(window);
	var headerParent = header.parent('.sticky-header');

	function resizeHandler() {
		var scrollTop = win.scrollTop();
		if (header.length) {
			if (scrollTop > 0) {
				header.addClass('header-fixed');
			}	 else {
				header.removeClass('header-fixed');
			}
		}
	}
	resizeHandler();
	win.on('scroll', resizeHandler);
}


function initFilter() { 
	var $container = jQuery('.filter-container');
	var select = jQuery('.filter-block .nav-select');

	$container.imagesLoaded( function(){
		$container.isotope({
			itemSelector: '.grid-item',
			layoutMode: 'fitRows' ,
			resize: true,
			gutter: 0,
			isFitWidth: true,
		});
		AOS.refresh();
	});

	jQuery('.filter-list a').each(function() {
		if (jQuery(this).closest('li').hasClass('active')) {
			var value = jQuery(this).attr('href').split('#')[1] == 'all' ? '*' : '[data-filter*=' + jQuery(this).attr('href').split('#')[1] + ']';
			$container.isotope({ filter: value });
		}

	});

	if (location.hash.length && location.hash.replace(/_/g, ' ').slice(1) != 'section1') {

		setTimeout(function() {
			var value = location.hash.replace(/_/g, ' ').slice(1) == 'all' ? '*' : '[data-filter*=' + location.hash.replace(/_/g, ' ').slice(1) + ']';
			$container.isotope({
				filter: value
			});

			jQuery('.filter-list a').each(function() {
				var currentid = jQuery('#' +  window.location.href.split('#')[1]);

				if (window.location.href.split('#')[1] == jQuery(this).attr('href').split('#')[1]) {
					jQuery('.filter-list li').removeClass('active');
					jQuery(this).closest('li').addClass('active');
				}
			}, 200);
		})
	}
}



function switchTabBySelect() {
	jQuery('.filter-list').each(function(e){
		var tabSet = jQuery(this);
		var links = tabSet.find('a');
		var select = tabSet.siblings('select');

		links.on('click', function(e){
			// e.preventDefault();
			select.prop('selectedIndex', links.index(jQuery(this)));
		});

		select.on('change', function(e){
			links.eq(this.selectedIndex).trigger('click');
		});
	});
}

// generate select from navigation
function initNavigationSelect() {
	jQuery('.filter-list').navigationSelect({
		levelIndentHTML: '&bull; ',
		defaultOptionAttr: 'title',
		useDefaultOption: false,
		selectClass: 'select-nav',
		activeClass: 'active'
	});
}

function initCountDown() {
	jQuery('[data-count]').each(function() {
		var dataCount = jQuery(this);
		var countDownDate = new Date(jQuery(this).data('count')).getTime();

		var x = setInterval(function() {
			var now = new Date().getTime();
			var distance = countDownDate - now;
			var days = Math.floor(distance / (1000 * 60 * 60 * 24));
			var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			var seconds = Math.floor((distance % (1000 * 60)) / 1000);



			if (dataCount.data('lang') == 'eng') {
				if(window.innerWidth > 767) {
					dataCount.html(days + "<span>days</span>" + hours + "<span>hours</span>"
						+ minutes + "<span>minutes</span>" + seconds + "<span>seconds</span>");
				} else {
					dataCount.html(days + "<span>d</span>" + hours + "<span>h</span>"
						+ minutes + "<span>m</span>" + seconds + "<span>s</span>");
				}
				
			} else {
				dataCount.html(days + "<span>天</span>" + hours + "<span>時</span>"
					+ minutes + "<span>分</span>" + seconds + "<span>秒</span>");
			}


			if (distance < 0) {
				clearInterval(x);
				dataCount.html('EXPIRED');
			}
		}, 1000);
	});
	
}

// lightbox init
function initFancybox() {

	jQuery('a.lightbox, [data-fancybox]').fancybox({
		parentEl: 'body',
		margin: [50, 0],
		touch: false

	});
	
}

// mobile menu init
function initMobileNav() {
	jQuery('body').mobileNav({
		menuActiveClass: 'nav-active',
		menuOpener: '.nav-opener',
		hideOnClickOutside: true,
		menuDrop: '.nav-drop'
	});

	jQuery('.shareit').mobileNav({
		menuActiveClass: 'active',
		menuOpener: '.opener',
		hideOnClickOutside: true,
		menuDrop: '.drop'
	});
}



/*
 * Simple Mobile Navigation
 */
 ;(function($) {
 	function MobileNav(options) {
 		this.options = $.extend({
 			container: null,
 			hideOnClickOutside: false,
 			menuActiveClass: 'nav-active',
 			menuOpener: '.nav-opener',
 			menuDrop: '.nav-drop',
 			toggleEvent: 'click',
 			outsideClickEvent: 'click touchstart pointerdown MSPointerDown'
 		}, options);
 		this.initStructure();
 		this.attachEvents();
 	}
 	MobileNav.prototype = {
 		initStructure: function() {
 			this.page = $('html');
 			this.container = $(this.options.container);
 			this.opener = this.container.find(this.options.menuOpener);
 			this.drop = this.container.find(this.options.menuDrop);
 		},
 		attachEvents: function() {
 			var self = this;

 			if(activateResizeHandler) {
 				activateResizeHandler();
 				activateResizeHandler = null;
 			}

 			this.outsideClickHandler = function(e) {
 				if(self.isOpened()) {
 					var target = $(e.target);
 					if(!target.closest(self.opener).length && !target.closest(self.drop).length) {
 						self.hide();
 					}
 				}
 			};

 			this.openerClickHandler = function(e) {
 				e.preventDefault();
 				self.toggle();
 			};

 			this.opener.on(this.options.toggleEvent, this.openerClickHandler);
 		},
 		isOpened: function() {
 			return this.container.hasClass(this.options.menuActiveClass);
 		},
 		show: function() {
 			this.container.addClass(this.options.menuActiveClass);
 			if(this.options.hideOnClickOutside) {
 				this.page.on(this.options.outsideClickEvent, this.outsideClickHandler);
 			}
 		},
 		hide: function() {
 			this.container.removeClass(this.options.menuActiveClass);
 			if(this.options.hideOnClickOutside) {
 				this.page.off(this.options.outsideClickEvent, this.outsideClickHandler);
 			}
 		},
 		toggle: function() {
 			if(this.isOpened()) {
 				this.hide();
 			} else {
 				this.show();
 			}
 		},
 		destroy: function() {
 			this.container.removeClass(this.options.menuActiveClass);
 			this.opener.off(this.options.toggleEvent, this.clickHandler);
 			this.page.off(this.options.outsideClickEvent, this.outsideClickHandler);
 		}
 	};

 	var activateResizeHandler = function() {
 		var win = $(window),
 		doc = $('html'),
 		resizeClass = 'resize-active',
 		flag, timer;
 		var removeClassHandler = function() {
 			flag = false;
 			doc.removeClass(resizeClass);
 		};
 		var resizeHandler = function() {
 			if(!flag) {
 				flag = true;
 				doc.addClass(resizeClass);
 			}
 			clearTimeout(timer);
 			timer = setTimeout(removeClassHandler, 500);
 		};
 		win.on('resize orientationchange', resizeHandler);
 	};

 	$.fn.mobileNav = function(opt) {
 		var args = Array.prototype.slice.call(arguments);
 		var method = args[0];

 		return this.each(function() {
 			var $container = jQuery(this);
 			var instance = $container.data('MobileNav');

 			if (typeof opt === 'object' || typeof opt === 'undefined') {
 				$container.data('MobileNav', new MobileNav($.extend({
 					container: this
 				}, opt)));
 			} else if (typeof method === 'string' && instance) {
 				if (typeof instance[method] === 'function') {
 					args.shift();
 					instance[method].apply(instance, args);
 				}
 			}
 		});
 	};
 }(jQuery));


/*

/*
 * Convert navigation to select
 */
 ;(function($) {
 	function NavigationSelect(options) {
 		this.options = $.extend({
 			list: null,
 			levelIndentHTML: ' &bull; ',
 			defaultOptionAttr: 'title',
 			defaultOptionText: '...',
 			selectClass: 'nav-select',
 			activeClass: 'nav-active',
 			defaultOptionClass: 'opt-default',
 			hasDropClass: 'opt-sublevel',
 			levelPrefixClass: 'opt-level-',
 			useDefaultOption: true
 		}, options);
 		if(this.options.list) {
 			this.createSelect();
 			this.attachEvents();
 		}
 	}
 	NavigationSelect.prototype = {
 		createSelect: function() {
 			var self = this;
 			this.startIndex = 0;
 			this.navigation = $(this.options.list);
 			this.select = $('<select>').addClass(this.options.selectClass);
 			this.createDefaultOption();
 			this.createList(this.navigation, 0);
 			this.select.insertBefore(this.navigation);
 		},
 		createDefaultOption: function() {
 			if(this.options.useDefaultOption) {
 				var attrText = this.navigation.attr(this.options.defaultOptionAttr);
 				var defaultOption = $('<option>').addClass(this.options.defaultOptionClass).html(attrText || this.options.defaultOptionText);
 				this.navigation.removeAttr(this.options.defaultOptionAttr);
 				this.select.append(defaultOption);
 				this.startIndex = 1;
 			}
 		},
 		createList: function(list, level) {
 			var self = this;
 			list.children().each(function(index, item) {
 				var listItem = $(this),
 				listLink = listItem.find('a').eq(0),
 				listDrop = listItem.find('ul').eq(0),
 				hasDrop = listDrop.length > 0;

 				if(listLink.length) {
 					self.select.append(self.createOption(listLink, hasDrop, level, listLink.hasClass(self.options.activeClass)));
 				}
 				if(hasDrop) {
 					self.createList(listDrop, level + 1);
 				}
 			});
 		},
 		createOption: function(link, hasDrop, level, selected) {
 			var optionHTML = this.getLevelIndent(level) + link.html();
 			return $('<option>').html(optionHTML)
 			.addClass(this.options.levelPrefixClass + (level + 1))
 			.toggleClass(this.options.hasDropClass, hasDrop)
 			.val(link.attr('href')).attr('selected', selected ? 'selected' : false);
 		},
 		getLevelIndent: function(level) {
 			return (new Array(level + 1)).join(this.options.levelIndentHTML);
 		},
 		attachEvents: function() {
   // redirect on select change
   var self = this;
   this.select.change(function() {
    // if(this.selectedIndex >= self.startIndex) {
    //  location.href = this.value;
    // }
});
}
};

 // jquery pluginm interface
 $.fn.navigationSelect = function(opt) {
 	return this.each(function() {
 		new NavigationSelect($.extend({list: this}, opt));
 	});
 };
}(jQuery));


/*
 * jQuery Open/Close plugin
 */
 ;(function($) {
 	function OpenClose(options) {
 		this.options = $.extend({
 			addClassBeforeAnimation: true,
 			hideOnClickOutside: false,
 			activeClass: 'active',
 			opener: '.opener',
 			slider: '.slide',
 			animSpeed: 400,
 			effect: 'fade',
 			event: 'click'
 		}, options);
 		this.init();
 	}
 	OpenClose.prototype = {
 		init: function() {
 			if (this.options.holder) {
 				this.findElements();
 				this.attachEvents();
 				this.makeCallback('onInit', this);
 			}
 		},
 		findElements: function() {
 			this.holder = $(this.options.holder);
 			this.opener = this.holder.find(this.options.opener);
 			this.slider = this.holder.find(this.options.slider);
 		},
 		attachEvents: function() {
			// add handler
			var self = this;
			this.eventHandler = function(e) {
				e.preventDefault();
				if (self.slider.hasClass(slideHiddenClass)) {
					self.showSlide();
				} else {
					self.hideSlide();
				}
			};
			self.opener.on(self.options.event, this.eventHandler);

			// hover mode handler
			if (self.options.event === 'hover') {
				self.opener.on('mouseenter', function() {
					if (!self.holder.hasClass(self.options.activeClass)) {
						self.showSlide();
					}
				});
				self.holder.on('mouseleave', function() {
					self.hideSlide();
				});
			}

			// outside click handler
			self.outsideClickHandler = function(e) {
				if (self.options.hideOnClickOutside) {
					var target = $(e.target);
					if (!target.is(self.holder) && !target.closest(self.holder).length) {
						self.hideSlide();
					}
				}
			};

			// set initial styles
			if (this.holder.hasClass(this.options.activeClass)) {
				$(document).on('click touchstart', self.outsideClickHandler);
			} else {
				this.slider.addClass(slideHiddenClass);
			}
		},
		showSlide: function() {
			var self = this;
			if (self.options.addClassBeforeAnimation) {
				self.holder.addClass(self.options.activeClass);
			}
			self.slider.removeClass(slideHiddenClass);
			$(document).on('click touchstart', self.outsideClickHandler);

			self.makeCallback('animStart', true);
			toggleEffects[self.options.effect].show({
				box: self.slider,
				speed: self.options.animSpeed,
				complete: function() {
					if (!self.options.addClassBeforeAnimation) {
						self.holder.addClass(self.options.activeClass);
					}
					self.makeCallback('animEnd', true);
				}
			});
		},
		hideSlide: function() {
			var self = this;
			if (self.options.addClassBeforeAnimation) {
				self.holder.removeClass(self.options.activeClass);
			}
			$(document).off('click touchstart', self.outsideClickHandler);

			self.makeCallback('animStart', false);
			toggleEffects[self.options.effect].hide({
				box: self.slider,
				speed: self.options.animSpeed,
				complete: function() {
					if (!self.options.addClassBeforeAnimation) {
						self.holder.removeClass(self.options.activeClass);
					}
					self.slider.addClass(slideHiddenClass);
					self.makeCallback('animEnd', false);
				}
			});
		},
		destroy: function() {
			this.slider.removeClass(slideHiddenClass).css({
				display: ''
			});
			this.opener.off(this.options.event, this.eventHandler);
			this.holder.removeClass(this.options.activeClass).removeData('OpenClose');
			$(document).off('click touchstart', this.outsideClickHandler);
		},
		makeCallback: function(name) {
			if (typeof this.options[name] === 'function') {
				var args = Array.prototype.slice.call(arguments);
				args.shift();
				this.options[name].apply(this, args);
			}
		}
	};

	// add stylesheet for slide on DOMReady
	var slideHiddenClass = 'js-slide-hidden';
	(function() {
		var tabStyleSheet = $('<style type="text/css">')[0];
		var tabStyleRule = '.' + slideHiddenClass;
		tabStyleRule += '{position:absolute !important;left:-9999px !important;top:-9999px !important;display:block !important}';
		if (tabStyleSheet.styleSheet) {
			tabStyleSheet.styleSheet.cssText = tabStyleRule;
		} else {
			tabStyleSheet.appendChild(document.createTextNode(tabStyleRule));
		}
		$('head').append(tabStyleSheet);
	}());

	// animation effects
	var toggleEffects = {
		slide: {
			show: function(o) {
				o.box.stop(true).hide().slideDown(o.speed, o.complete);
			},
			hide: function(o) {
				o.box.stop(true).slideUp(o.speed, o.complete);
			}
		},
		fade: {
			show: function(o) {
				o.box.stop(true).hide().fadeIn(o.speed, o.complete);
			},
			hide: function(o) {
				o.box.stop(true).fadeOut(o.speed, o.complete);
			}
		},
		none: {
			show: function(o) {
				o.box.hide().show(0, o.complete);
			},
			hide: function(o) {
				o.box.hide(0, o.complete);
			}
		}
	};

	// jQuery plugin interface
	$.fn.openClose = function(opt) {
		var args = Array.prototype.slice.call(arguments);
		var method = args[0];

		return this.each(function() {
			var $holder = jQuery(this);
			var instance = $holder.data('OpenClose');

			if (typeof opt === 'object' || typeof opt === 'undefined') {
				$holder.data('OpenClose', new OpenClose($.extend({
					holder: this
				}, opt)));
			} else if (typeof method === 'string' && instance) {
				if (typeof instance[method] === 'function') {
					args.shift();
					instance[method].apply(instance, args);
				}
			}
		});
	};
}(jQuery));